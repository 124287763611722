import React from "react";
import cardThumb from '../../media/image/Card.jpg';
import './Card.css';

export const Card = () => {
    return (
        <div className="intro-card">
            <div dangerouslySetInnerHTML={{ __html: "<!-- Shoutout to @wongmjane for the design :) -->" }} />
            <div className="intro-card-title">
                <div>
                    <a href="https://github.com/arshwaraich" rel="noopener noreferrer" target="_blank">
                        <h1><span>Arshdeep Singh</span>
                        <span className="rocket"> / <span role="img" aria-label="Rocket-emoji" id="rocket">🚀</span></span>
                    </h1>
                    </a>
                    <h2>ਅਰਸ਼</h2>
                </div>
                <div className="title-img">
                    <img className="img-fluid" src={cardThumb} alt="CardThumbnail" id="CardThumb" />
                </div>
            </div>
            <div className="content">
                <div>
                    <p>💼</p>
                    <p>Sr. Software Developer @ Royal Bank of Canada</p>
                </div>
                <div>
                    <p>🏫</p>
                    <p>Hon. Bachelor of Technology in Software Development</p>
                </div>
                <div>
                    <p>📍</p>
                    <p>Toronto, Ontario, Canada</p>
                </div>
            </div>
            <div className="misc">
                <div className="misc-left">
                    <a href="mailto:arshwaraich@duck.com?Subject=Hello%20there!" rel="noopener noreferrer" target="_blank">
                        <div className="pill">✉️&nbsp;&nbsp;Email</div>
                    </a>
                    <a href="https://github.com/arshwaraich/Resume/blob/master/resume.pdf">
                        <div className="pill">📄&nbsp;&nbsp;Resume</div>
                    </a>
                </div>
                <a href="#about">
                    <div className="pill noborder">...</div>
                </a>
            </div>
        </div>
    );
};