import React, { useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import '../App/App.css';
import './Contact.css';
import useIntersection from '../../utils/useIntersection'

mapboxgl.accessToken = 'pk.eyJ1IjoiYXJzaHdhcmFpY2giLCJhIjoiY2w0ZXI1c3MwMDY5aDNvcGcwZ3FzdDVwYSJ9.3ZrboIcCpVMXIMahD2q-XQ';

function Contact(prop) {
  const ref = useRef();
  const inViewport = useIntersection(ref, '0px');
  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = -79.3832;
  const lat = 43.6532;
  const zoom = 9;

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/cje59h2m22g4x2sjzbsv1lc42',
      center: [lng, lat],
      zoom: zoom
    });
  });

  return (
    <div className='contact'>
      {/* Contact details */}

      <div className="row no-gutters">
        <div className="col-md-4" style={{ padding: '40px' }}>
          <h1>
            Say Hi!&nbsp;
            <span ref={ref} role="img" aria-label="hi-emoji" className={inViewport ? 'wave' : ''}>🤙</span>
          </h1>
          <div>
            <p className="mb-0"><b>Email</b></p>
            <a href="mailto:arshwaraich@duck.com">arshwaraich@duck.com</a>
          </div>
          <div>
            <p className="mb-0"><b>Phone</b></p>
            <a href="tel:+16472938485">+1(647)-293-8485</a>
          </div>
          <div>
            <p className="mb-0"><b>Address</b></p>
            <p>Toronto, ON, Canada</p>
          </div>
          <div id="easteregg">
            <p className="mb-0"><b>Easter egg<span role="img" aria-label="easter-emoji">🐰</span></b></p>
            <p>Hover over my name in the card :)</p>
          </div>
        </div>
        <div className="col-md-8">
          <div ref={mapContainer} className="map-container" />
        </div>
      </div>
    </div>
  );
}

export default Contact;