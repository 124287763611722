import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import '../App/App.css';
import './Resume.css';
import MugShot from '../../media/image/Resume.jpg';

const EmojiBox = ({url, emoji, title}) => {
  return (
    <li><a href={url}>{title}</a></li>
  )
};

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markdown: null
    };
  }

  componentDidMount() {
    const resumePath = "https://api.github.com/repos/arshwaraich/Resume/readme";

    fetch(resumePath, {
      method: 'GET'
    })
      .then(res => res.json())
      .then(response => {
        return atob(response.content);
      })
      .then(text => {
        this.setState({
          markdown: text
        });
      });
  }

  render() {
    return (
      <div className="container card-1">
        {/* What i do? */}
        <div className="row" id="bigcard">
          <div className="col-md-3" id="ResumeBar">
            <img className="img-fluid" src={MugShot} alt="Arsh_MugShot" id="MugShot" />
            <h1>Arshdeep Singh</h1>
            <p id="subHead">Software developer</p>
            <div>
              <h2>Personal Info.</h2>
              <div className='wave-line'></div>
              <p><b>Address</b></p>
              <p>
                <a
                  href="https://web.archive.org/web/20240606205824/https://en.wikipedia.org/wiki/Toronto#Technology_and_biotech"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="resumeBarLink">
                  Toronto, ON, Canada
                </a>
              </p>
              <p><b>Phone</b></p>
              <p><a href="tel:+1-647-293-8485" rel="noopener noreferrer" target="_blank" className="resumeBarLink">+1(647)-293-8485</a></p>
              <p><b>Email</b></p>
              <p><a href="mailto:arshwaraich@duck.com?Subject=Hello%20there!" rel="noopener noreferrer" target="_blank" className="resumeBarLink">arshwaraich@duck.com</a></p>
              <p><b>Linkedin</b></p>
              <p><a href="https://www.linkedin.com/in/arshwaraich" rel="noopener noreferrer" target="_blank" className="resumeBarLink">linkedin.com/in/arshwaraich</a></p>
              <p><b>Github</b></p>
              <p><a href="https://github.com/arshwaraich" rel="noopener noreferrer" target="_blank" className="resumeBarLink">github.com/arshwaraich</a></p>
            </div>

            <div>
              <h2>Alt. Formats</h2>
              <div className='wave-line'></div>
              <ul className='pl-4'>
                <li><a href="https://github.com/arshwaraich/Resume/blob/master/resume.pdf">PDF</a></li>
                <li><a href="https://github.com/arshwaraich/Resume/blob/master/README.md">Markdown</a></li>
                <li><a href="https://github.com/arshwaraich/Resume/blob/master/LaTeX/resume.tex">LaTeX</a></li>
                <li><a href="https://github.com/arshwaraich/Resume/">Repository</a></li>
              </ul>
            </div>

            <div className='interests'>
              <h2>Interests</h2>
              <div className='wave-line'></div>
              <ul className='pl-4'>
                <EmojiBox emoji='🏎️' title='Motorsports' url='https://www.formula1.com/en/racing/2024.html'/>
                <EmojiBox emoji='🧳' title='Travelling' url='https://en.wikipedia.org/wiki/Long_Way_Round#Overview'/>
                <EmojiBox emoji='🛞' title='Cars' url='https://web.archive.org/web/20200324100158/https://www.youtube.com/watch?v=aQ8xDia5RNY'/>
                <EmojiBox emoji='🎬' title='Movies' url='https://www.rogerebert.com/great-movies'/>
                <EmojiBox emoji='🕹️' title='Video Games' url='https://web.archive.org/web/20180118023355/https://www.youtube.com/watch?v=e7Z2I9x4Pd0'/>
                <EmojiBox emoji='🎧' title='Music' url='https://music.apple.com/ca/album/across-the-universe-2021-mix/1582219768?i=1582222018'/>
                <EmojiBox emoji='🍳' title='Cooking' url='https://web.archive.org/web/20210307081321/https://www.theguardian.com/food/2021/mar/07/how-marco-pierre-whites-white-heat-launched-a-culinary-revolution'/>
                <EmojiBox emoji='🎤' title='Comedy' url='https://web.archive.org/web/20230605114719/https://www.newyorker.com/culture/postscript/norm-macdonald-was-the-real-thing'/>
                <EmojiBox emoji='🥾' title='Hiking' url='https://web.archive.org/web/20240228181913/https://www.toronto.ca/explore-enjoy/recreation/walking-hiking/trails/'/>
                <EmojiBox emoji='📚' title='Books' url='https://en.wikipedia.org/wiki/All_Quiet_on_the_Western_Front'/>
              </ul>
            </div>
          </div>
          <div className="col-md-9" id="ResumeBody">
            <ReactMarkdown children={this.state.markdown} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
      </div>
    );
  }
}

export default Resume;