import React, { Component } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import 'mapbox-gl/dist/mapbox-gl.css';
import About from '../About/About';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="container-fluid">
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={
              <div>
                <About />
              </div>} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        <footer>
          <p>&copy; 2024, Arshdeep Singh</p>
        </footer>
      </div>
    );
  }
}

export default App;

const NotFound = () => {
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div className='notfound'>
        <h1>This isn't a recognized path</h1>
        <p>The path seldom taken, sometimes isn't a path at all.</p>
        <p>Click <Link to="/">here</Link> to go home.</p>
      </div>
    </div>
  );
}