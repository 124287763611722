import React, { Component } from 'react';
import '../App/App.css';
import './About.css';
import Resume from '../Resume/Resume';
import Contact from '../Contact/Contact';
import { Card } from '../Card/Card';

class About extends Component {

  render() {
    return (
      <div>
        <div className="big-banner">
          {/* <video autoPlay muted playsInline loop src="http://192.168.2.26:8080/video.mp4" type="video/mp4" id="bg-video" /> */}
          <Card />
        </div>
        <div id="about" style={{
          display: 'hidden'
        }} />
        <div className='about-section'>
          <div className="col-md-8 px-5 pt-5 mx-auto" style={{
            minHeight: '100vh',
            paddingBottom: '20vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <div>
              <h1>About me</h1>
              <hr className="bg-light" />
              <p>
                I'm Arshdeep Singh, a Full Stack Software developer. I'm currently working as a full-stack software developer at the Royal Bank of Canada in Toronto.
              </p>
              <p>
                I have a profound interest in software and all things tech. My journey into software began early—I built my first website in grade 7 after diving into a web development course, and I've been obsessed with tech ever since. Between my full-time job and other responsibilities, I often work on side projects, always seeking to hack at everyday problems with skills I continually develop and refine.
              </p>
              <p>
                Software development is an ever-changing, challenging field. I love to code and hope to make a career out of it, gathering skills and making important connections with like-minded people along the way.
              </p>
            </div>
          </div>
        </div>
        <div id="resume" className='resume-section' />
        <div style={{
          position: 'relative',
          top: '-20vh',
          padding: '0px 1em'
        }}>
          <Resume />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </div >
    );
  }
}

export default About;